import React, {useState} from "react"
import './nav-bar-sort-menu.css';
import {useDispatch, useSelector} from "react-redux";
import {getQueryLinkParams} from "../../redux/lots/action";
import {find} from "lodash/collection";
import WithSuspense from "../repeating-components/wrapper-components/with-suspense";
import {Container, Nav, Navbar} from "react-bootstrap";
import {split} from "lodash/string";
import {filter} from "lodash/collection";

const AuctionCategoryBlock = React.lazy(() => import('./auction-category-block'));
const OwnerCategoryBlock = React.lazy(() => import('./owner-category-block'));
const SearchBlock = React.lazy(() => import('./search-block'));
const AvatarBlock = React.lazy(() => import('./avatar-block'));
const ArchiveSwitchBlock = React.lazy(() => import('./archive-switch-block'));

const NavBarSortMenu = () =>  {

    const [category_title, setCategoryTitle] = useState('');
    const dispatch = useDispatch();

    const {searchAuctions, all_categories, ak_au_ph_sort_by_category, user_lots_type, auction_category_id, routeName} = useSelector(
        state => {
        const {lots, router} = state

        const arr = filter(split(router.location.pathname, '/', 2), Boolean);
        const {user_lots_type, auction_category_id} = dispatch(getQueryLinkParams());

        return {
            user_lots_type,
            auction_category_id,
            routeName: (arr.length > 0) ? arr[0] : '',
            all_categories: lots.all_categories,
            searchAuctions: lots.visitor.permissions.auctions,
            ak_au_ph_sort_by_category: lots.phrases.ak_au_ph_sort_by_category
        }
    })

    React.useEffect(() => {
        let value = ak_au_ph_sort_by_category;
        if (auction_category_id !== 0) {
            const category = find(all_categories, {auction_category_id});
            value = category.category_title
        }
        setCategoryTitle(value);
    }, [auction_category_id, ak_au_ph_sort_by_category, all_categories]);

    const types = ['archive_lots', 'auction_lots'];
    const categoryBlock = (types.includes(user_lots_type))
        ? <AuctionCategoryBlock category_title={category_title}/>
        : <OwnerCategoryBlock category_title={category_title}/>;

    const avatarBlock = (user_lots_type === 'archive_lots') ? <ArchiveSwitchBlock/> : <AvatarBlock/>;
    const searchBlock = (searchAuctions) ? <SearchBlock/> : null;

    const wrapper = (component) => {
        return <WithSuspense>{component}</WithSuspense>
    }

    return (
        <Navbar className="rounded m-1" collapseOnSelect expand="lg" bg={"success"} variant={"dark"}>
            <Container>
                {wrapper(avatarBlock)}
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">

                    </Nav>
                    <div className="d-flex flex-wrap">
                        {routeName !== 'subscriptions' ? wrapper(categoryBlock) : null}
                        {wrapper(searchBlock)}
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};


export default NavBarSortMenu;



