import React, {useCallback} from 'react'
import WithSuspense from "../../repeating-components/wrapper-components/with-suspense";
import ModalContent from "../modal-follow-content/modal-content";
import {useDispatch, useSelector} from "react-redux";
import {getQueryLinkParams, setAllCategories} from "../../../redux/lots/action";
import {setParamsFallowCategoryModal} from "../../../redux/modals/modal-follow-category/actionFollowCategory";
import {find} from "lodash/collection";
import ReibertApiService from "../../../services/reibert-api-service";
import SubscriptionsApiService from "../../../services/subscriptions-api-service";


const ModalBlackBacground = React.lazy(() => import('../../repeating-components/wrapper-components/modal-black-background'));

const ModalFollowCategory = () => {

    const dispatch = useDispatch();

    const {all_categories = {}, visitor_user_id, phrases, modalShow = false, auction_category_id} = useSelector((state) => {

        const {lots, modalFollowCategoryReducer} = state;

        return {
            all_categories: lots.all_categories,
            visitor_user_id: lots.visitor_user_id,
            phrases: lots.phrases,
            auction_category_id: dispatch(getQueryLinkParams()).auction_category_id,
            modalShow: modalFollowCategoryReducer.modalShow
        };
    });

    const setShowModal = useCallback(() => {
        dispatch(setParamsFallowCategoryModal());
    }, [dispatch, modalShow]);

    const condition = visitor_user_id === find(all_categories, {auction_category_id})?.user_id;

    const sendForm = useCallback(() => {

        let obj = {
            auction_category_id,
        };

        const form = document.forms.follow;

        if (condition) {
            obj.stop = 'stop';
        } else {
            obj.watch_type = form.watch_type.value;
        }

        const {setFollowCategory} = new SubscriptionsApiService();

        setFollowCategory(obj).then((json) => {

            const {_redirectStatus} = json

            if ('error' in json) {
                alert(json.error[0])
                return
            }

            if (_redirectStatus === 'ok') {
                const arr = all_categories.map(val => {
                    if (val.auction_category_id === auction_category_id) {
                        val.user_id = condition ? null : visitor_user_id;
                    }
                    return val;
                });

                dispatch(setAllCategories(arr));
            }

            setShowModal();

        });
    }, [all_categories, auction_category_id, visitor_user_id, dispatch, setShowModal]);

    const {ak_au_no_watch_category = '', ak_au_watch_category = '', ak_au_stop_watch_lot = '', ak_au_watch_lot = ''} = phrases;
    const phraseSubmit = condition ? ak_au_stop_watch_lot : ak_au_watch_lot;
    const phraseTitle = (condition) ? ak_au_no_watch_category : ak_au_watch_category;

    if (modalShow) {
        return (
            <WithSuspense>
                <ModalBlackBacground
                    sendForm={sendForm}
                    setShow={setShowModal}
                    show={modalShow}
                    phraseTitle={phraseTitle}
                    phraseSubmit={phraseSubmit}
                >
                    <ModalContent condition={condition} phrases={phrases}/>
                </ModalBlackBacground>
            </WithSuspense>
        )
    }

    return null;
}
export default ModalFollowCategory