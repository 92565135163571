import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setDataImageGallery} from "../../../redux/modals/modal-image-gallery/actionImageGallery";
import helpers from "../../../services/helpers";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
import "yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css";
import {Thumbnails} from "yet-another-react-lightbox/dist/plugins";
import './mig.css'

const ModalImageGallery = React.memo(() => {
    const dispatch = useDispatch();

    const {modalShow, imageIndex, attachments} = useSelector(
        state => state.imageGalleryReducer
    );

    const setShowModal = useCallback(() => {
        dispatch(setDataImageGallery({modalShow: false}));
    }, [dispatch]);

    const images = useMemo(() => {
        // Assuming attachments is an array of objects with a `viewUrl` property
        return attachments.map((attachment) => ({
            src: `${helpers.getBaseUrl()}/${attachment.viewUrl}`,
        }));
    }, [attachments]);

    return (
        <>
            {modalShow && (
                <Lightbox
                    open={modalShow}
                    close={setShowModal}
                    slides={images}
                    plugins={[Thumbnails]}
                    index={imageIndex}
                    carousel={{finite: true, preload: 6}}
                    controller={{closeOnBackdropClick: true, closeOnPullUp: true}}
                />
            )}
        </>
    );
}, (prevProps, nextProps) => (prevProps.modalShow === nextProps.modalShow && prevProps.attachments === nextProps.attachments));

export default ModalImageGallery;
