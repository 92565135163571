import React from 'react'
import {useDispatch, useSelector} from "react-redux";
import {getQueryLinkParams} from "../../../redux/lots/action";

const useTopBarButtonFn = () => {
    
    const dispatch = useDispatch();

    const obj = useSelector((state) => {
        const {lots, router} = state;

        return {
            all_categories: lots.all_categories,
            visitor_user_id: lots.visitor_user_id,
            phrases: lots.phrases,
            user_prefixes: lots.user_prefixes,
            prefix_id: router.location.query.prefix_id,
            auction_category_id: dispatch(getQueryLinkParams()).auction_category_id,
        };
    });

    return {...obj};

}

export default useTopBarButtonFn