import React, {useCallback} from "react";
import {setParamsFallowCategoryModal} from "../../../../redux/modals/modal-follow-category/actionFollowCategory";
import {find} from "lodash/collection";
import useTopBarButtonFn from "../useTopBarButtonFn";
import {useDispatch} from "react-redux";

const FollowCategory = () => {

    const dispatch = useDispatch();

    const {visitor_user_id, auction_category_id, all_categories, phrases:{ak_au_no_watch_category, ak_au_watch_category}} = useTopBarButtonFn()

    const setShowModal = useCallback(() => {
        dispatch(setParamsFallowCategoryModal());
    }, [dispatch]);

    const {user_id} = find(all_categories, {auction_category_id});
    const condition = (visitor_user_id === user_id);

    const buttonPhrase = (condition) ? ak_au_no_watch_category : ak_au_watch_category;

    return (
        <div className="ml-1">
            <button type="button" onClick={() => setShowModal()} className="btn btn-success btn-sm btn-rg">
                {buttonPhrase}
            </button>
        </div>
    )

}
export default FollowCategory
