import BaseApiService from "./base-api-service";


export default class AuctionApiService extends BaseApiService {
    constructor() {
        super();
        this._apiBase = `${this._baseUrl}/auctions`;
    }

    saveQuestionData = async (obj) => {
        return await this.getResource(`/add-post`, obj, 'POST');
    }

    updateQuestionData = async (obj) => {
        return await this.getResource(`/edit-post`, obj, 'POST');
    }

    deleteQuestionData = async (obj) => {
        return await this.getResource(`/delete-post`, obj, 'POST');
    }

    reportQuestion = async (obj) => {
        return await this.getResource(`/message-report`, obj, 'POST');
    }

    restoreQuestionData = async (obj) => {
        return await this.getResource(`/restore-post`, obj, 'POST');
    }
}