import React from 'react';
import {useSelector} from "react-redux";
import ModalImageGallery from "../modals/modal-image-gallery";
import ModalSortingSettings from "../modals/modal-sorting-settings";
import ModalPrefix from "../modals/modal-prefix";
import ModalFollowPrefix from "../modals/modal-follow-prefix/modal-follow-prefix";
import ModalFollowCategory from "../modals/modal-follow-category";
import ModalPromotionLots from "../modals/modal-promotion-lots";
import ModalCategorySettings from "../modals/modal-category-settings/modal-category-settings";
import ModalInlineModCheck from "../modals/modal-inline-mod-check";

const Modals = () => {
    const {
        modalInlineShow, modalFollowPrefixShow, modalSortingSettingShow,
        modalPrefixShow, modalFollowCategoryShow, modalPromotionShow, modalCategorySettingsShow, modalImageGalleryShow,
    } = useSelector(state => {
        const {
            modalFollowPrefixReducer,
            sortingSetting,
            modalFollowCategoryReducer,
            modalPromotion,
            imageGalleryReducer,
            categorySettingsReducer,
            modalPrefix,
            modal,
        } = state

        return {
            modalInlineShow: modal.modalShow,
            modalFollowPrefixShow: modalFollowPrefixReducer.modalShow,
            modalSortingSettingShow: sortingSetting.modalShow,
            modalFollowCategoryShow: modalFollowCategoryReducer.modalShow,
            modalCategorySettingsShow: categorySettingsReducer.modalShow,
            modalPrefixShow: modalPrefix.modalShow,
            modalImageGalleryShow: imageGalleryReducer.modalShow,
            modalPromotionShow: modalPromotion.modalShow,
        }
    });

    return (
        <>
            {modalImageGalleryShow ? <ModalImageGallery/> : null}
            {modalSortingSettingShow ? <ModalSortingSettings/> : null}
            {modalPrefixShow ? <ModalPrefix/> : null}
            {modalFollowPrefixShow ? <ModalFollowPrefix/> : null}
            {modalFollowCategoryShow ? <ModalFollowCategory/> : null}
            {modalPromotionShow ? <ModalPromotionLots/> : null}
            {modalCategorySettingsShow ? <ModalCategorySettings/> : null}
            {modalInlineShow ? <ModalInlineModCheck/> : null}
        </>
    )

}
export default Modals