import {FALLOW_PREFIX_SET_DATA} from "../../types";

const initialState = {
    modalShow: false
}

export const modalFollowPrefixReducer = (state = initialState, action) => {

    switch (action.type) {
        case FALLOW_PREFIX_SET_DATA :
            return {...state, ...action.payload};
        default:
            return state;
    }

}