import React from "react";


const ModalContent = ({phrases, condition}) => {

    const {
        ak_au_stop_watch_this_cat,
        ak_au_watch_this_lot,
        ak_au_watch_and_alert,
        ak_au_watch_and_alert_and_email
    } = phrases

    if (condition) {
        return (
            <div className="row justify-content-center">
                <input type="hidden" name="stop" value="stop"/>
                <span className='text-white float-right h6'>{ak_au_stop_watch_this_cat}</span>
            </div>
        )
    }

    return (
        <form id='follow' name='followCategory'>
            <div className="row justify-content-center">
                <div className="col mx-2 align-self-start">
                    <span className='text-white float-right h6'>{ak_au_watch_this_lot}...</span>
                </div>
                <div className="col mx-2  align-self-start">
                    <ul className="nav flex-column ">
                        <li className="nav-item">
                            <input type="radio" name="watch_type" value="2" id="ctrl_email_subscribe_1"
                                   defaultChecked={true} autoFocus={true}/>
                            <label className="form-check-label" htmlFor="ctrl_email_subscribe_1">
                                <p className="h6 text-white m-1"> {ak_au_watch_and_alert} </p>
                            </label>
                        </li>
                        <li className="nav-item">
                            <input type="radio" name="watch_type" value="3" id="ctrl_email_subscribe_2"/>
                            <label className="form-check-label" htmlFor="ctrl_email_subscribe_2">
                                <p className="h6 text-white m-1"> {ak_au_watch_and_alert_and_email} </p>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
        </form>
    )
}
export default ModalContent