import {SET_DATA_SUBSCRIPTION} from "../types";

const initialState = {
    subscriptionCategories: [],
    subscriptionPrefixes: [],
    subscriptionLoading : true,
};

export const subscriptionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATA_SUBSCRIPTION:
            return {...state, ...action.payload};
        default:
            return state
    }
}