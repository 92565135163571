export const GET_DFAULT_LOTS_LIST_PARAMS = 'LOTS/GET_DFAULT_LOTS_LIST_PARAMS';
export const UPDATE_LOTS_DATA = 'LOTS/UPDATE_LOTS_DATA';
export const LOTS_LOADING = 'LOTS/LOTS_LOADING';
export const ON_SUBMIT_SEARCH = 'LOTS/ON_SUBMIT_SEARCH';

export const SET_ALL_CATEGORIES = 'LOTS/SET_ALL_CATEGORIES';
export const SET_USER_PREFIXES = 'LOTS/SET_USER_PREFIXES';
export const SET_TYPE = 'LOTS/SET_TYPE';
export const SET_CHANGE_LOT = 'LOTS/SET_CHANGE_LOT';
export const DELETE_CHANGE_LOT = 'LOTS/DELETE_CHANGE_LOT';
export const SET_MODAL = 'LOTS/SET_MODAL';
export const CHANGE_ALL_LOTS = 'LOTS/CHANGE_ALL_LOTS';
export const SET_PAGE = 'LOTS/SET_PAGE';
export const ON_CHANGE_YEARS = 'LOTS/ON_CHANGE_YEARS';
export const ON_TOGGLE_ALL_LOTS = 'MODAL/ON_TOGGLE_ALL_LOTS';
export const ON_CHANGE_ACTION = 'MODAL/ON_CHANGE_ACTION';
export const ON_CHANGE_CATEGORY = 'MODAL/ON_CHANGE_CATEGORY';
export const ON_CHANGE_COPY_ACTION = 'MODAL/ON_CHANGE_COPY_ACTION';
export const ON_CHANGE_REMOVE_ACTION = 'MODAL/ON_CHANGE_REMOVE_ACTION';
export const SET_ERRORS = 'MODAL/SET_ERRORS';

export const TOGGLE_MODAL = 'MODAL/TOGGLE_MODAL';
export const SET_OPTIONS = 'MODAL/SET_OPTIONS';

export const SET_PREFIX_MODAL = 'MODAL_PREFIX/SET_PREFIX_MODAL';
export const SET_DATE_MODAL = 'MODAL_SET_DATE/SET_DATE_MODAL';

export const SET_END_TIME_MODAL = 'MODAL_SET_END_TIME/SET_END_TIME'

export const SET_PARAMS_PROMOTION_LOTS = 'MODAL_PROMOTION_lOTS/SET_PARAMS_PROMOTION_LOTS';

export const SET_PARAMS_SORTING_SETTINGS = 'MODAL_SORTING_SETTINGS/SET_PARAMS_SORTING_SETTINGS';

export const SET_CATEGORY_TITLE = 'NAV_BAR/SET_CATEGORY_TITLE';

//SIDEBAR
export const SET_SIDEBAR_DEFAULT_LOADING_DATA = 'SIDEBAR/SET_SIDEBAR_DEFAULT_LOADING_DATA';
export const SET_DEFAULT_PARAMS = 'SIDEBAR/SET_DEFAULT_PARAMS';
export const SET_TYPE_SIDEBAR = 'SIDEBAR/SET_TYPE_SIDEBAR_NPE';
export const SET_SIDEBAR_LOTS_DATA = 'SIDEBAR/SET_SIDEBAR_LOTS_DATA';
export const SET_SIDEBAR_LOADING_LOTS_DATA = 'SIDEBAR/SET_SIDEBAR_LOADING_LOTS_DATA';

//USER SIDE BAR
export const SET_SIDEBAR_BLOCK_DATA = 'USER_SIDEBAR/SET_SIDEBAR_BLOCK_DATA';
export const SET_SIDEBAR_LOTS_COUNT = 'USER_SIDEBAR/SET_SIDEBAR_LOTS_COUNT';
export const SET_SIDEBAR_LOADING_LOTS_IN_SIDEBAR = 'SIDEBAR/SET_SIDEBAR_LOADING_LOTS_IN_SIDEBAR';
// FILTERS SIDEBAR
export const SET_FILTERS_SIDEBAR_BLOCK_DATA = 'FILTERS_SIDEBAR/SET_FILTERS_SIDEBAR_BLOCK_DATA';
export const SET_FILTERS_SIDEBAR_LOADING = 'FILTERS_SIDEBAR/SET_FILTERS_SIDEBAR_LOADING';
export const SET_FILTER = 'FILTERS_SIDEBAR/SET_FILTER';
export const SET_FILTERS = 'FILTERS_SIDEBAR/SET_FILTERS';
//CATEGORIES_SIDEBAR
export const SET_CATEGORIES = 'CATEGORIES_SIDEBAR/SET_CATEGORIES';
export const SET_SHOW_BLOCK_CATEGORIES = 'CATEGORIES_SIDEBAR/SET_SHOW_BLOCK_CATEGORIES';

//MODERATORS_SIDEBAR
export const SET_ONLINE_MODERATORS = 'MODERATORS_SIDEBAR/SET_ONLINE_MODERATORS';
export const SET_SHOW_BLOCK_ONLINE_MODERATORS =  'MODERATORS_SIDEBAR/SET_SHOW_BLOCK_ONLINE_MODERATORS';

//ONLINE_USERS_SIDEBAR
export const SET_SHOW_BLOCK_ONLINE_USERS = 'ONLINE_USERS_SIDEBAR/SET_SHOW_BLOCK_ONLINE_MODERATORS';
export const SET_DATA_ONLINE_USERS = 'ONLINE_USERS_SIDEBAR/SET_DATA_ONLINE_USERS';

//SIDEBAR_STATS
export const SET_DATA_SIDEBAR_STATS = 'SIDEBAR_STATS/SET_DATA_SIDEBAR_STATS';
export const SET_SHOW_BLOCK_SIDEBAR_STATS = 'SIDEBAR_STATS/SET_SHOW_BLOCK_SIDEBAR_STATS';

//SIDEBAR_ARCHIVE
export const SET_DATAlINK_SIDEBAR_ARCHIVE = 'SIDEBAR_ARCHIVE/SET_DATA_lINK_SIDEBAR_ARCHIVE';

//TOP_BAR
export const SET_INTERESTING_LOTS_DATA  = 'TOP_BAR/SET_INTERESTING_LOTS_DATA';

//MODAL_TRADING_DURATION

export const SET_DAYS = 'MODAL_TRADING_DURATION/SET_DAYS';

export const SET_DATA_CATEGORY_SETTINGS = 'MODAL_CATEGORY_SETTINGS/SET_DATA_CATEGORY_SETTINGS'


export const SET_DATA_REQUISITES = 'REQUISITES/SET_DATA_REQUISITES';
export const SET_DELIVERY_INPUTS = 'REQUISITES/SET_DELIVERY_INPUTS';
export const SET_PAYMENT_INPUTS = 'REQUISITES/SET_PAYMENT_INPUTS';
export const SET_ERROR_FORM = 'REQUISITES/SET_ERROR_FORM';

//Crud
export const SET_CRUD_DATA = 'CRUD/SET_CRUD_DATA';
export const SET_LOT_PARAMS = 'CRUD/SET_LOT_PARAMS';
export const SET_LOT_CATEGORY_ID = 'CRUD/SET_LOT_CATEGORY_ID';
export const SET_DELIVERY_PARAMS = 'CRUD/SET_DELIVERY_PARAMS';
export const SET_ERROR_DELIVERY = 'CRUD/SET_ERROR_DELIVERY';
export const SET_IMAGES = 'CRUD/SET_IMAGES';
export const SET_PAYMENT_PARAMS = 'CRUD/SET_PAYMENT_PARAMS';
export const SET_ERROR_PAYMENT = 'CRUD/SET_ERROR_PAYMENT';
export const SET_LOT_OPTIONS = 'CRUD/SET_LOT_OPTIONS';
export const SET_LOT_PREFIX = 'CRUD/SET_LOT_PREFIX';
export const SET_ERROR_SUBMIT_FORM = 'CRUD/SET_ERROR_SUBMIT_FORM';
export const SET_PROMOTION_DATA = 'CRUD/SET_PROMOTION_DATA';
export const SET_IMAGE_ORDER = 'CRUD/SET_IMAGE_ORDER';

//LOT PAGE

export const SET_ATTACHMENTS_FORM_DISPUT_MESSAGE = 'LOT_PAGE/SET_ATTACHMENTS_FORM_DISPUT_MESSAGE'
export const SET_LOT_DATA = 'LOT_PAGE/SET_LOT_DATA'
export const UPDATE_AUCTION_OPTION = 'LOT_PAGE/UPDATE_AUCTION_OPTION'

//SET TITLE MODAL
export const SET_DATA_TITLE_MODAL = 'SET_TITLE_MODAL/SET_DATA'

export const SET_DATA_REMOVE_LOT_MODAL = 'MODAL_REMOVE_LOT/SET_DATA'
export const SET_DATA_FINISH_LOT_MODAL = 'MODAL_FINISH_LOT/SET_DATA'
export const FALLOW_CATEGORY_SET_DATA = 'MODAL_FALLOW_CATEGORY/SET_DATA'
export const FALLOW_PREFIX_SET_DATA = 'MODAL_FALLOW_PREFIX/SET_DATA'

export const SET_DATA_REMOVE_LOT_FROM_SALE_MODAL = 'MODAL_REMOVE_LOT_FROM_SALE/SET_DATA'

export const SET_PARAMS_LOT_LOGS = 'MODAL_LOT_lOGS/SET_PARAMS'
export const SET_DATA_EDIT_PRICE = 'MODAL_EDIT_PRICE/SET_PARAMS'
export const SHOW_MODAL_EDIT_PRICE = 'MODAL_EDIT_PRICE/SHOW_MODAL'
export const SET_DATA_ADDITIONAL_INFO = 'MODAL_ADDITIONAL_INFO/SET_PARAMS'

export const SET_DATA_IMAGE_GALLERY = 'MODAL_IMAGE_GALLERY/SET_PARAMS'

export const SET_QUESTION_DATA = 'MODAL_REPLY_QUESTION/SET_PARAMS'
export const SET_PENALTY_FOR_MESSAGE_DATA = 'MODAL_PENALTY_FOR_MESSAGE/SET_PARAMS'

export const SET_DISPUT_DATA = 'DISPUT/SET_PARAMS'

export const SET_DATA_FORM_SEND_INFO = 'FORM_SEND_INFO/SET_PARAMS'

export const SET_PARAMS_MODAL_VIEW_LOT_SALE = 'MODAL_VIEW_LOT_SALE/SET_PARAMS'

export const SET_DATA_LOT_QUANTITY = 'MODAL_EDIT_LOT_QUANTITY/SET_PARAMS'

export const SET_DATA_META_TAGS = 'META_TAGS/SET_PARAMS'
export const SET_PAGE_NAV_DATA = 'PAGE_NAV/SET_PARAMS'

export const SET_DATA_SUBSCRIPTION = 'SUBSCRIPTION/SET_PARAMS'
