import React from "react";
import WithSuspense from "../../repeating-components/wrapper-components/with-suspense";
import {useDispatch, useSelector} from "react-redux";
import {
    _defaultStateData,
    setDataCategorySettings,
} from "../../../redux/modals/modal-category-settings/actionCategorySettings";
import ModalContent from "./modal-content";
import {getQueryLinkParams} from "../../../redux/lots/action";
import {find} from "lodash/collection";

const ModalBlackBacground = React.lazy(() => import('../../repeating-components/wrapper-components/modal-black-background'));

const ModalCategorySettings = () => {
    const dispatch = useDispatch();
    const {categoryState, phrases, all_categories, auction_category_id= 0} = useSelector(state => {
        const {lots, categorySettingsReducer} = state
        const {auction_category_id} = dispatch(getQueryLinkParams());

        return {
            phrases: lots.phrases,
            all_categories: lots.all_categories,
            auction_category_id: auction_category_id,
            categoryState: categorySettingsReducer
        }
    });

    const {ak_au_ph_my_rates, ak_au_learn_more} = phrases;
    const {modalShow} = categoryState;

    const sendForm = () => {
        window.location.href = `/threads/tarify-aukciona.905644`;
    }

    const handleShow = () => {
        const obj = _defaultStateData()
        dispatch(setDataCategorySettings(obj))
    }

    const getTitle = () => {
        const {category_title} = find(all_categories, {auction_category_id});
        return ak_au_ph_my_rates.replace(/\{name\}/, category_title)
    }

    if (modalShow) {
        return (
            <WithSuspense>
                <ModalBlackBacground
                    setShow={handleShow}
                    show={modalShow}
                    phraseSubmit={ak_au_learn_more}
                    phraseTitle={getTitle()}
                    sendForm={sendForm}
                >
                    <ModalContent phrases={phrases} {...categoryState}/>
                </ModalBlackBacground>
            </WithSuspense>
        )
    }
    ;

    return null;

}

export default ModalCategorySettings