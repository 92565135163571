import React from "react";
import {useDispatch, useSelector} from "react-redux";
import WithSuspense from "../../repeating-components/wrapper-components/with-suspense";
import {
    _defaultStateData,
    sendFormPromotion,
    setParamsPromotionLots
} from "../../../redux/modals/modal-promotion-lots/actionPromotionLots";
import PromotionContent from "./promotion-content";

const ModalBlackBacground = React.lazy(() => import('../../repeating-components/wrapper-components/modal-black-background'));

const ModalPromotionLots = () => {

    const dispatch = useDispatch();
    const {phrases, modalShow,error} = useSelector(state => {
        const {lots, modalPromotion} = state;
        return {
            phrases: lots.phrases,
            modalShow: modalPromotion.modalShow,
            error: modalPromotion.error
        }
    })
    const {save, ak_au_promotion_lot_change_phrase} = phrases

    const handleShow = () => {
        const obj = _defaultStateData()
        dispatch(setParamsPromotionLots(obj))
    }

    const sendForm = () => {
        dispatch(sendFormPromotion())
    }

    if (modalShow) {
        return (
            <WithSuspense>
                <ModalBlackBacground
                    setShow={handleShow}
                    show={modalShow}
                    phraseSubmit={save}
                    phraseTitle={ak_au_promotion_lot_change_phrase}
                    error={error}
                    sendForm={sendForm}
                >
                    <PromotionContent/>
                </ModalBlackBacground>
            </WithSuspense>
        )
    }

    return null

}
export default ModalPromotionLots