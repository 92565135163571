import React, {useEffect, useMemo, useState} from "react";

import WithSuspense from "../../repeating-components/wrapper-components/with-suspense";
import {useDispatch, useSelector} from "react-redux";
import {setParamsSortingSettings, updateUserSortingSetting} from "../../../redux/modals/modal-sorting-settings/actionSortingSettings";
import SortingSettingsContent from "./sorting-settings-content";
import Helpers from "../../../services/helpers";
import {find} from "lodash/collection";
import {getQueryLinkParams} from "../../../redux/lots/action";
import InputShowImgList from "./input-show-img-list";


const ModalBlackBacground = React.lazy(() => import('../../repeating-components/wrapper-components/modal-black-background'));

const ModalSortingSettings = () => {

    const dispatch = useDispatch();

    const {router, lotsState, modalShow} = useSelector(state => {
        const {router, lots, sortingSetting} = state
        return {
            router,
            lotsState: lots,
            modalShow: state.sortingSetting.modalShow
        }
    });

    const {save, ak_au_ph_sorting_settings} = lotsState.phrases

    const [directionData, setDirectionData] = useState({})
    const [order, setOrder] = useState('')
    const [direction, setDirection] = useState('')
    const [showImgLotsList, setShow] = useState(1)
    const [sortingData, setSortingData] = useState([])

    const {search: urlSearch, pathname} = router.location

    useEffect(() => {

        if (modalShow) {
            const query = dispatch(getQueryLinkParams());
            setOrder(query.order);
            const sortingData = Helpers.getSortingData({...lotsState, ...query})
            setSortingData(sortingData)
        }

    }, [modalShow, urlSearch, pathname])


    useEffect(() => {
        if (modalShow && sortingData.length !== 0) {
            const {defaultDirection, direction} = find(sortingData, {order});
            setDirectionData(defaultDirection)
            setDirection(direction)
        }
    }, [modalShow, order, sortingData])

    const handleShow = () => {
        dispatch(setParamsSortingSettings({modalShow: !modalShow}))
    }

    const sendForm = () => {
        const {type, user_lots_type} = dispatch(getQueryLinkParams());
        const sortObj = {user_lots_type, type, direction, sort_order: order, show_img_lots_list: showImgLotsList ? 1 : 0, _xfConfirm: 1};
        dispatch(updateUserSortingSetting(sortObj));
        handleShow()
    }

    if (modalShow && direction !== '' && order !== '') {
        return (
            <WithSuspense>
                <ModalBlackBacground
                    setShow={handleShow}
                    show={modalShow}
                    phraseSubmit={save}
                    sendForm={sendForm}
                    phraseTitle={ak_au_ph_sorting_settings}
                >
                    <SortingSettingsContent
                        order={order}
                        setOrder={setOrder}
                        direction={direction}
                        setDirection={setDirection}
                        directionData={directionData}
                        sortingData={sortingData}
                    />
                    <InputShowImgList
                        setShow={setShow}
                        showImgLotsList={showImgLotsList}
                    />
                </ModalBlackBacground>
            </WithSuspense>
        )
    }

    return null

}
export default ModalSortingSettings;