import React, {useMemo} from 'react'
import {useDispatch} from "react-redux";
import useTopBarButtonFn from "../useTopBarButtonFn";
import {find} from "lodash/collection";
import {setParamsFallowPrefixModal} from "../../../../redux/modals/modal-follow-prefix/actionFollowPrefix";


const FollowCategoryPrefix = () => {

    const dispatch = useDispatch();
    const {phrases: {ak_au_follow_prefix, ak_au_unfollow_prefix}, user_prefixes, prefix_id, visitor_user_id} = useTopBarButtonFn();

    const buttonPhrase = useMemo(() => {

        if (user_prefixes.length !== 0) {
            const {user_id} = find(user_prefixes, {prefix_id: parseInt(prefix_id)});
            return (visitor_user_id === user_id) ? ak_au_unfollow_prefix : ak_au_follow_prefix;
        }

        return ak_au_follow_prefix;

    }, [user_prefixes, prefix_id, visitor_user_id])

    const setShowModal = () => {
        dispatch(setParamsFallowPrefixModal())
    }

    return (
        <div className="ml-1">
            <button type="button" onClick={() => setShowModal()} className="btn btn-success btn-sm btn-rg">
                {buttonPhrase}
            </button>
        </div>
    )

}
export default FollowCategoryPrefix