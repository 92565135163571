import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {changeAllLots, getQueryLinkParams, setLotsData} from "../../redux/lots/action";
import {getSideBarDefaultParams} from "../../redux/sidebars/actionSideBar";
import {getFilters, setFilters} from "../../redux/sidebars/filter-sidebar/actionFilterSideBar";
import {toggleModal} from "../../redux/modals/modal-inline-mod-check/actionModal";

const useLotsFn = () => {
    const dispatch = useDispatch();

    const {startLoading, simulateLoading, changeLots, lotsData, user_sort_data, lots, router} = useSelector(state => {
        const {lots, router} = state

        return {
            startLoading: lots.startLoading,
            simulateLoading: lots.simulateLoading,
            changeLots: lots.changeLots,
            lotsData: lots.lotsData,
            user_sort_data: lots.user_sort_data,
            lots,
            router
        }
    });

    const {search: urlSearch, pathname} = router.location

    const query = useMemo(() => {
        if (startLoading) {
            return {}
        }
        return dispatch(getQueryLinkParams());
    }, [user_sort_data, urlSearch, pathname]);

    const {user_lots_type = ''} = query


    useEffect(() => {
        const filters = dispatch(getFilters());
        dispatch(setFilters(filters));
        dispatch(setLotsData());
        dispatch(getSideBarDefaultParams())
    }, [urlSearch, pathname]);

    useEffect(() => {
        dispatch(changeAllLots(changeLots.length === lotsData.length));
        if (changeLots.length === 0) {
            dispatch(toggleModal(false));
        }
    }, [changeLots])

    return {
        startLoading, simulateLoading, changeLots, lotsData, user_lots_type, lotsState: lots, query
    }
}
export default useLotsFn
