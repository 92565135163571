import React, {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {Route, Switch} from 'react-router'
import {ConnectedRouter} from 'connected-react-router'
import configureStore, {history} from './redux/configureStore'
import './index.css';
import Lots from "./components/lots";

// const Lots = React.lazy(() => import('./components/lots/lots'));
const Requisites = React.lazy(() => import('./components/requisites'));
const LotPage = React.lazy(() => import('./components/lot-page'));
const CreateLot = React.lazy(() => import('./components/crud-lot/create-lot'));
const EditLot = React.lazy(() => import('./components/crud-lot/edit-lot'));
const Subscriptions = React.lazy(() => import('./components/subscriptions'));


const store = configureStore()

const root = createRoot(document.getElementById('root'));


root.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <Switch>
                <Route exact path="/" render={() => (
                    <Lots/>
                )}/>
                <Route exact path="/auctions/" render={() => (
                    <Lots/>
                )}/>
                <Route exact path="/requisites/" render={() => (
                    <Suspense fallback={<div>Loading...</div>}>
                        <Requisites/>
                    </Suspense>
                )}/>
                <Route path="/auction-history/:action" render={() => (
                    <Lots/>
                )}/>
                <Route exact path="/auction/:user_id/:type?/" render={() => (
                    <Lots/>
                )}/>
                <Route exact path="/auctions/:auction_category_id" render={() => (
                    <Lots/>
                )}/>
                <Route exact path="/archive/:year/:type/:auction_category_id?/" render={() => (
                    <Lots/>
                )}/>
                <Route exact path="/auction-process/create/:auction_category_id?/" render={() => (
                    <Suspense fallback={<div>Loading...</div>}>
                        <CreateLot/>
                    </Suspense>
                )}/>
                <Route exact path="/auction-process/edit/:auction_id?/" render={() => (
                    <Suspense fallback={<div>Loading...</div>}>
                        <EditLot/>
                    </Suspense>
                )}/>

                <Route exact path="/lots/:auction_id?/" render={() => (
                    <Suspense fallback={<div>Loading...</div>}>
                        <LotPage/>
                    </Suspense>
                )}/>

                <Route exact path="/subscriptions/:action" render={() => (
                    <Suspense fallback={<div>Loading...</div>}>
                        <Subscriptions/>
                    </Suspense>
                )}/>
            </Switch>
        </ConnectedRouter>
    </Provider>
);



