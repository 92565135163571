import React, {useCallback} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setUserPrefixes} from "../../../redux/lots/action";

import {find} from "lodash/collection";
import ReibertApiService from "../../../services/reibert-api-service";
import WithSuspense from "../../repeating-components/wrapper-components/with-suspense";
import ModalContent from "../modal-follow-content/modal-content";
import {setParamsFallowPrefixModal} from "../../../redux/modals/modal-follow-prefix/actionFollowPrefix";
import SubscriptionsApiService from "../../../services/subscriptions-api-service";


const ModalBlackBacground = React.lazy(() => import('../../repeating-components/wrapper-components/modal-black-background'));

const ModalFollowPrefix = () => {

    const dispatch = useDispatch();

    const {user_prefixes = [], visitor_user_id, phrases, modalShow = false, prefix_id} = useSelector((state) => {

        const {lots, modalFollowPrefixReducer, router} = state;

        return {
            user_prefixes: lots.user_prefixes,
            prefix_id: router.location.query.prefix_id,
            visitor_user_id: lots.visitor_user_id,
            phrases: lots.phrases,
            modalShow: modalFollowPrefixReducer.modalShow
        };
    });

    const setShowModal = useCallback(() => {
        dispatch(setParamsFallowPrefixModal());
    }, [dispatch, modalShow]);

    let condition = false

    if (user_prefixes.length !== 0) {
        const {user_id} = find(user_prefixes, {prefix_id: parseInt(prefix_id)})
        condition = visitor_user_id === user_id;
    }

    const sendForm = useCallback(() => {

        let obj = {
            prefix_id: prefix_id,
        };

        const form = document.forms.follow;

        if (condition) {
            obj.stop = 'stop';
        } else {
            obj.watch_type = form.watch_type.value;
        }

        const {setFollowPrefix} = new SubscriptionsApiService();

        setFollowPrefix(obj).then((json) => {

            const {_redirectStatus, user_prefixes} = json

            if ('error' in json) {
                alert(json.error[0])
                return
            }

            if (_redirectStatus === 'ok') {
                dispatch(setUserPrefixes(user_prefixes));
            }

            setShowModal();

        });
    }, [user_prefixes, prefix_id, visitor_user_id, dispatch, setShowModal]);

    const {ak_au_unfollow_prefix = '', ak_au_follow_prefix = ''} = phrases;
    const phraseSubmit = condition ? ak_au_unfollow_prefix : ak_au_follow_prefix;
    const phraseTitle = phraseSubmit;

    if (modalShow) {
        return (
            <WithSuspense>
                <ModalBlackBacground
                    sendForm={sendForm}
                    setShow={setShowModal}
                    show={modalShow}
                    phraseTitle={phraseTitle}
                    phraseSubmit={phraseSubmit}
                >
                    <ModalContent condition={condition} phrases={phrases}/>
                </ModalBlackBacground>
            </WithSuspense>
        )
    }

    return null;
}

export default ModalFollowPrefix